import React from 'react'
import { Link } from 'react-router-dom'

import styles from './events.module.scss'

const EventsPage = () => {
  return (
    <>
      <section className={styles.events}>
        <div className={styles.content}>
          <div className={styles.top}>
            <div className={styles.img}>
              <img
                src='/event1.jpg'
                alt='Свадьба в ресторане «REGUL»'
              />
            </div>
            <div className={styles.title}>
              <h1>Свадьба в ресторане «REGUL»</h1>
            </div>
          </div>
          <div className={styles.text}>
            <h4>Ищете ресторан для камерной свадьбы в центре Москвы?</h4>
            <p>
              Предлагаем премиум-ресторан «REGUL», где к Вашим услугам ряд несомненных преимуществ:
            </p>
            <ul>
              <li>Идеальное расположение – в тихом переулке в самом центре столицы.</li>
              <li>
                Благородные интерьеры и современные дизайнерские решения в старинном особняке XIX
                века.
              </li>
              <li>
                Команда ресторана «REGUL», которая поможет реализовать лучшую концепцию Вашего
                торжества.
              </li>
              <li>
                Роскошный банкет, который запомнится Вашим гостям оригинальной подачей и потрясающе
                вкусными блюдами.
              </li>
              <li>
                Транспортное удобство –для тех гостей, кто на машине- есть собственная парковка
                ресторана, кто на общественном транспорте – 8 минут пешком до метро, а иногородним
                мы можем организовать комфортабельный трансфер.
              </li>
              <li>
                В уютном ресторане рассадка идет всего до 20 человек. А значит вам и вашим гостям
                гарантирована приватная обстановка.
              </li>
              <li>
                На летней веранде в окружении зелени предусмотрены всего 35 посадочных мест. В
                формате фуршета есть возможность размещения до 45 гостей. Живая зеленая изгородь
                создает атмосферу закрытого клуба только для своих. Ваши гости будут в восторге.
              </li>
            </ul>
            <p>
              Дополнительным плюсом является возможность заселения гостей в номера пятизвездочного
              бутик-отеля c приятной скидкой. А шикарным подарком для молодоженов -{' '}
              <Link to='/shares/1'>номер «Люкс»</Link>.
            </p>
            <p>
              Ваша уютная маленькая свадьба в самом центре Москвы станет большим событием, которое
              запомнится на всю жизнь атмосферой, которую, мы уверены, вам захочется не только тепло
              вспоминать, но и повторить в золотую годовщину.
            </p>
            <div className={styles.actions}>
              <p className={styles.notice}>Позвоните нам, и мы организуем Вам свадьбу мечты</p>
              <a
                target='_blank'
                rel='noreferrer'
                href='tel:+74994041764'
              >
                +7 (499) 404-17-64
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default EventsPage
