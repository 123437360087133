import React, { useState } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

import Logo from '../../ui/logo'

import arrowIcon from '../../../assets/img/arrow.svg'
import awayIcon from '../../../assets/img/away.svg'

import styles from './header.module.scss'

const Header = ({ className }) => {
  const [showMenu, setShowMenu] = useState(false)

  const burgerClickHandler = (e) => {
    setShowMenu((prev) => !prev)
    console.log(showMenu)
  }

  return (
    <header className={clsx(styles.header, className)}>
      <Logo className={styles.logo} />
      <nav className={clsx(styles.nav, showMenu && styles.show)}>
        <ul className={styles.menu}>
          <li className={clsx(styles.menu_item, styles.menu_item_parent)}>
            <span className={styles.menu_nonlink}>
              О ресторане
              <span className={clsx(styles.icon, styles.icon_arrow)}>
                <img
                  src={arrowIcon}
                  alt='arrow'
                />
              </span>
            </span>
            <ul className={clsx(styles.menu, styles.menu_sub)}>
              <li className={clsx(styles.menu_item)}>
                <Link
                  onClick={() => setShowMenu(false)}
                  className={styles.menu_link}
                  to='/gallery'
                >
                  Фотографии
                </Link>
              </li>
              <li className={clsx(styles.menu_item)}>
                <Link
                  onClick={() => setShowMenu(false)}
                  className={styles.menu_link}
                  to='/funny'
                >
                  Развлечения
                </Link>
              </li>
              <li className={clsx(styles.menu_item)}>
                <Link
                  onClick={() => setShowMenu(false)}
                  className={clsx(styles.menu_link, styles.hasIcon)}
                  target='_blank'
                  rel='noopener'
                  to='https://russeasonshotel.ru/'
                >
                  Бутик-отель «Regul»
                  <span className={clsx(styles.icon, styles.icon_away)}>
                    <img
                      src={awayIcon}
                      alt='away'
                    />
                  </span>
                </Link>
              </li>
            </ul>
          </li>
          <li className={clsx(styles.menu_item)}>
            <Link
              onClick={() => setShowMenu(false)}
              className={styles.menu_link}
              to='/shares'
            >
              Акции
            </Link>
          </li>
          <li className={clsx(styles.menu_item)}>
            <Link
              onClick={() => setShowMenu(false)}
              className={styles.menu_link}
              to='/events'
            >
              Мероприятия
            </Link>
          </li>
          <li className={clsx(styles.menu_item, styles.menu_item_parent)}>
            <span className={clsx(styles.menu_nonlink, styles.hasIcon)}>
              Меню
              <span className={clsx(styles.icon, styles.icon_arrow)}>
                <img
                  src={arrowIcon}
                  alt='arrow'
                />
              </span>
            </span>
            <ul className={clsx(styles.menu, styles.menu_sub)}>
              <li className={clsx(styles.menu_item)}>
                <Link
                  onClick={() => setShowMenu(false)}
                  className={styles.menu_link}
                  rel='noopener'
                  target='_blank'
                  to='/menu-ru-eng.pdf'
                >
                  Основное меню PDF
                </Link>
              </li>
              {/* <li className={clsx(styles.menu_item)}>
                <Link
                  onClick={() => setShowMenu(false)}
                  className={styles.menu_link}
                  rel='noopener'
                  target='_blank'
                  to='/set-menu.pdf'
                >
                  Сет-меню PDF
                </Link>
              </li> */}
            </ul>
          </li>
          <li className={clsx(styles.menu_item)}>
            <Link
              onClick={() => setShowMenu(false)}
              className={clsx(styles.menu_link)}
              to='/loyalty'
            >
              Программа лояльности
            </Link>
          </li>
          <li className={clsx(styles.menu_item)}>
            <Link
              onClick={() => setShowMenu(false)}
              className={styles.menu_link}
              to='/contacts'
            >
              Контакты
            </Link>
          </li>
        </ul>
      </nav>
      <div
        onClick={burgerClickHandler}
        className={clsx(styles.burger, showMenu ? styles.burger_opened : styles.burger_closed)}
      >
        <div className={clsx(styles.burger_icon, styles.burger_icon_left)}></div>
        <div className={clsx(styles.burger_icon, styles.burger_icon_right)}></div>
      </div>
    </header>
  )
}

export default Header
