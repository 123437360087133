import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

import logoImg from '../../../assets/img/logo-en.png'

import styles from './logo.module.scss'

const Logo = ({ className }) => {
  return (
    <div className={clsx(styles.logo, className)}>
      <Link
        className={clsx(styles.link)}
        to='/'
      >
        <img
          src={logoImg}
          alt='Ресторан Сезоны'
        />
      </Link>
    </div>
  )
}

export default Logo
